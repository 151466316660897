@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-row { display:flex; flex-direction:row; align-items:center; gap:15px; margin:15px 0; }

.key-list__key { margin-bottom:10px;  display:flex; flex-direction:row; align-items:center; gap:15px;} 
.key-list__key p { margin:0; padding:0; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }

.location__admin { white-space:nowrap; }
