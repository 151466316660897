:root {
    --color-primary: #3aad87;
    --color-primary-dark: #2e8a6b;
    --color-secondary: #674cad;
    --color-secondary-light: #8c6db3;
    --color-gray-dark: #242324;
    --color-gray-light: #f1f1f1;
}
body {
    background: #fff;
    overflow-y:scroll;
}

a, button {
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}

.logo-wrapper { width:auto; aspect-ratio:1/1; height:50px; object-fit:contain; overflow:hidden; }

.login-container { 
    width: 400px;
    margin-top: 50px;
    text-align: center;
}

.sidenav-trigger { display: block !important; }

.google-account { display: flex; align-items: center; }
.account__image { border-radius: 50%; overflow: hidden; max-height: 50px; max-width: 50px; }
.account__image img { width: 100%; height: 100%; object-fit: cover; }
.account__content { display: flex; flex-direction: column; padding: 10px 15px; }
.account__name { font-size: 20px; }
.account__email { font-size: 14px; }

/* Locations List */
.location__details { display: flex; flex-direction: column; gap: 5px; }

.header { background: var(--color-gray-dark); }

.navigation { display: flex; gap: 10px; padding-top: 10px; padding-bottom: 10px; }
.navigation--home { justify-content: flex-end; }
.nav__link { padding: 4px 20px; background: var(--color-primary); color: #fff; text-decoration: none; border-radius: 20px; }
.nav__link:hover { background: var(--color-secondary); color: #fff; }

.profile { color: #fff; display: flex; gap: 20px; align-items: center; }

/* Main Content */
.main-content { padding: 30px 0; }
